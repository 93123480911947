
import { defineComponent, onMounted, ref, UnwrapRef } from "vue";
import * as yup from "yup";
import { notify } from "@/services/helpers";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import AuthStore from "@/store/auth-store";
import InputField from "@/components/InputField.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Dropdown from "@/components/DropdownComponent.vue";
import { IDepartment } from "@/types/Department";
import MultiSelect from "@/components/Multiselect.vue";

export default defineComponent({
  name: "NewRoster",
  components: { MultiSelect, Dropdown, InputField, ErrorMessage, Field, Form },
  setup(_, { emit }) {
    const api = new WorkForceService();

    const roles = AuthStore.getTokenData().Roles;

    const loading = ref(false);

    const rolesEnum = {
      admin: "Admin",
      manager: "Manager",
      orgAdmin: "Org Admin",
    };

    const newRoster = ref({
      id: 0,
      organisationId: 0,
      name: "",
      departmentId: "",
      shiftIds: [] as number[],
    });

    const shiftsModel = ref([]);

    const schema = yup.object().shape({
      name: yup.string().required("Name is required."),
      departmentId: yup.string().required("Please choose a department."),
      shiftsModel: yup
        .array()
        .min(1, "Please select at least 1 shift.")
        .required("Please select at least 1 shift."),
    });

    const departments = ref<IDepartment[]>([]);

    const shifts = ref([]);

    onMounted(async () => {
      await api
        .get<IDepartment[]>(`/departments?IsActive=true`, false)
        .then((response: any) => {
          departments.value = response.content.items;
        });
    });

    const fetchShift = async (selected: any) => {
      newRoster.value.shiftIds = [];
      shiftsModel.value = [];
      await api
        .get(`/Shifts/departments/${selected.id}`, false)
        .then((response: any) => {
          shifts.value = response.content;
        });
    };

    // Save Roster
    const saveRoster = () => {
      loading.value = true;

      newRoster.value.shiftIds = shiftsModel.value.map((item: any) => item.id);

      api
        .postOrPut<void>("/Rosters", newRoster.value, undefined)
        .then((response) => {
          loading.value = false;
          if (!response.isError) {
            notify("Roster created successfully.", "Success", "success");
            emit("roster-created");
          } else {
            notify(response.errors, "Error", "danger");
          }
        });
    };

    const cancelCreating = () => {
      emit("cancelled");
    };

    const calcHour = (startParam: string, endParam: string) => {
      const start: any = startParam.split(":");
      const end: any = endParam.split(":");
      const startDate = new Date(0, 0, 0, start[0], start[1], 0);
      const endDate = new Date(0, 0, 0, end[0], end[1], 0);
      let diff = endDate.getTime() - startDate.getTime();
      let hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      const minutes = Math.floor(diff / 1000 / 60);

      if (hours < 0) hours = hours + 24;

      return (
        (hours <= 9 ? "0" : "") +
        hours +
        ":" +
        (minutes <= 9 ? "0" : "") +
        minutes
      );
    };

    return {
      schema,
      saveRoster,
      cancelCreating,
      roles,
      newRoster,
      rolesEnum,
      loading,
      departments,
      shifts,
      fetchShift,
      calcHour,
      shiftsModel,
    };
  },
});
